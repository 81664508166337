body * {
  font-family: 'Sarabun', 'Poppins', Helvetica, sans-serif;
}

:root {
  --md-breakpoint: 768px;
  --A4-paper-width: 796.8px;
  --A4-paper-height: 1123.2px;
  --contact-bg-item: rgba(9, 30, 66, 0.04);
  --contact-bg-hover: #091e4224;
}

/* Font Size === */
.p1 {
  font-size: 16px;
}

.p2 {
  font-size: 14px;
}

.p3 {
  font-size: 12px;
}

.p4 {
  font-size: 10px;
}

.p5 {
  font-size: 8px;
}

.p6 {
  font-size: 6px;
}

/* === Font Weight === */
.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.grid-template-columns-none {
  grid-template-columns: none !important;
}

body input {
  cursor: auto;
}

.gray-modal-backdrop {
  opacity: 0 !important;
}

.gray-modal-wrapper .modal-dialog {
  justify-content: center;
}

.gray-modal-wrapper .modal-content {
  width: 240px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.5);
}

.gray-modal-wrapper .modal-body {
  border-radius: 5px;
}

@media (max-width: 400px) {
  .setting-page .card .card-body {
    padding: 10px 5px !important;
  }
}

.new-address .card .card-body {
  padding-inline: 0px;
}

.card.card-custom.product-edit-card,
.product-edit-card {
  margin-inline: 40px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

@media (max-width: 400px) {
  .card.card-custom.product-edit-card,
  .product-edit-card {
    margin-inline: 15px;
  }
}

.product-edit-page-container {
  padding-top: 55px;
  background-color: white !important;
}

.option-group-input {
  background: #ffffff;
  border: 1px solid #dadcdf;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  padding-left: 12px;
}

@media (max-width: 400px) {
  .option-group-input {
    padding-left: 8px;
  }

  .option-group-input.variant-option {
    font-size: 10px;
    height: 36px;
  }
}

.variant-table-wrapper {
  overflow: auto;
}

.row-variant2 {
  padding: 12px 12px 12px 12px;
  margin-inline: 0px;
}

.variant-image {
  width: 72px;
  height: 72px;
  margin-inline: 8px;
  border-radius: 4px;
  cursor: pointer;
  display: block;
}

.hoverable-image-wrapper {
  position: relative;
  margin: 8px 4px;
}

.hoverable-image-wrapper:hover > .hover-image-container {
  display: block;
  cursor: pointer;
}

.filter-blue {
  filter: invert(42%) sepia(99%) saturate(7162%) hue-rotate(240deg) brightness(100%) contrast(104%);
}

.default-variant-image {
  width: 40px;
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  background-color: rgba(255, 227, 227, 0.7);
}

.main-order-card-image {
  width: 35px;
  border-radius: 8px;
  margin-right: 9px;
}

.focus-outline-none:focus-visible {
  outline: none;
}
/* input:focus-visible {
  outline: none;
} */

@media (min-width: 450px) {
  .main-order-card-image {
    width: 60px;
  }
}

.login-phone-input {
  max-width: 150px;
  padding-bottom: 1rem;
}

.focus-outline-none:focus-visible {
  outline: none;
}

.custom-html p {
  margin-bottom: 0px;
}

.ck-balloon-panel {
  z-index: 9999 !important;
}

.super-admin-table > tbody > tr > td {
  vertical-align: middle;
}

li {
  list-style: none;
}

.card.card-custom {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.not-allowed {
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.form-control.form-control-disabled {
  color: light-dark(graytext, rgb(170, 170, 170));
  border-color: rgba(118, 118, 118, 0.3); 
  opacity: 0.75;
}

td.table-cell {
  font-family: Sarabun;
  font-size: 13.5px;
}

th.table-column-header {
  font-family: Sarabun;
  font-size: 13.5px;
}


.swal2-container {
  z-index: 10000;
}